import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Vimeo from "@u-wave/react-vimeo"
import { usePageSetup } from "@components/usePageSetup"
import { Layout } from "@components/Layout"
import { Seo } from "@components/Seo"
import { Hero } from "@components/Hero"
import { HeroImage, Intro, Category, MobileDesign, Pagination, Section } from "@components/Work"
import { Carousel } from "@components/Carousel"
import { CursorMarker } from "@components/CursorMarker"
import { Lottie } from "@components/Lottie"
import { Arrow } from "@components/UI"
import { splitText } from "@utils"
import IconographyDesktop from "@svg/work/weichert/iconography-desktop.svg"
import IconographyMobile from "@svg/work/weichert/iconography-mobile.svg"
import weichert2007Lotie from "@lottie/work/weichert/timeline-2007.json"
import weichert2017Lotie from "@lottie/work/weichert/timeline-2017.json"
import weichert2020Lotie from "@lottie/work/weichert/timeline-2020.json"
import weichert1985Lotie from "@lottie/work/weichert/timeline-1985.json"
import socialMediaThreeGif from "@images/work/weichert/social-media-03.gif"
import workData from "@json/work.json"

const WeichertFranchisingPage = ({ data }) => {
  const {
    webpageOneImage,
    webpageTwoImage,
    webpageThreeImage,
    mobileOneImage,
    mobileTwoImage,
    mobileThreeImage,
    printOneDesktopImage,
    printOneTabletImage,
    printTwoDesktopImage,
    printTwoTabletImage,
    printThreeDesktopImage,
    printThreeTabletImage,
    socialMediaOneImage,
    socialMediaTwoImage,
  } = data

  const { name, headline, theme, url } = workData["weichert"]
  const { themeColor } = usePageSetup({
    themeColor: theme.background,
    displayGeneralForm: false,
  })

  return (
    <Layout>
      <Seo title={name} description={headline} />
      {/* HERO */}
      <section className={themeColor}>
        <Hero className="pb-clamp-36-54 pt-clamp-16-26">
          <Hero.Title>
            <h1 className="tracking-normal text-18 text-gray-darkest">{name}</h1>
            <h2
              data-aos="stagger"
              className="text-work-headline mt-clamp-6-8"
              dangerouslySetInnerHTML={{
                __html: splitText({
                  "text-gray-darkest": headline,
                }),
              }}
            />
          </Hero.Title>
        </Hero>
      </section>

      <Arrow colorTop="bg-gray-darkest" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

      <main className="overflow-hidden">
        <HeroImage client="weichert" />
        <Category>Website development, B2B branding</Category>
        <Intro>
          <Intro.Overview>
            <Intro.Title>A more engaging website&mdash;and a smarter way of selling.</Intro.Title>
            <div className="mt-10">
              <p>
                Sometimes connecting with B2B prospects takes more than just a sharp new website—it takes telling a
                whole new story.
              </p>
              <p>
                That’s exactly what we did for our friends at Weichert Franchising, creating an entirely new voice, new
                value-driven messaging, and a refreshed brand look that captures Weichert’s approachable character while
                painting them in a compelling new light.
              </p>
            </div>
          </Intro.Overview>
          <Intro.Deliverables url={url}>
            <li>Research</li>
            <li>Persona development</li>
            <li>Creative strategy</li>
            <li>Messaging</li>
            <li>Visual identity</li>
            <li>Custom iconography</li>
            <li>UI/UX design</li>
            <li>Copywriting</li>
            <li>Website development</li>
          </Intro.Deliverables>
        </Intro>

        <section className="mt-clamp-34-29">
          <Section className="col-span-8 xl:col-span-7">
            <Section.Title>An intuitive new website and a new audience.</Section.Title>
            <Section.Description>
              <p>
                This updated identity was brought to life in a new website, one driven by a content strategy that
                provided just enough information for prospects to want to learn more and a design that celebrated the
                traditional hallmarks of the Weichert visual identity while making them feel more contemporary and
                relevant.
              </p>
              <p>
                The site was built to deliver an intuitive user experience on both desktop and mobile platforms, and
                crafted to appeal to the established real estate brokers of today while connecting with the brokers of
                tomorrow—a new audience JK discovered during our research—to build a sustainable pipeline of leads long
                into the future.
              </p>
            </Section.Description>
          </Section>

          <div className="relative mt-clamp-17-23">
            <div className="absolute inset-0 flex">
              <div
                className={`mt-auto w-full h-1/2 extend ${theme.background} ${theme.backgroundBefore} ${theme.backgroundAfter}`}
              ></div>
            </div>
            <div className="container">
              <div className="relative aspect-w-1920 aspect-h-1080">
                <div className="absolute inset-0">
                  <div data-aos="fade-up" className="overflow-hidden rounded-lg shadow">
                    <Vimeo video="597402177" controls={false} responsive muted autoplay />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* WEBPAGES */}
        <section className={`${theme.background} pt-clamp-15-42`}>
          <div className="container grid grid-cols-12 gap-x-2 lg:gap-x-4 grid-row-1">
            <div className="z-20 grid grid-cols-12 col-span-8 col-start-1 row-start-1 row-end-2 lg:col-end-7">
              <div className="col-span-11 lg:col-span-11">
                <div className="hidden lg:block aspect-w-647 aspect-h-158"></div>
                <div data-aos="fade-up">
                  <GatsbyImage
                    objectFit="contain"
                    className="overflow-hidden rounded-lg shadow"
                    image={getImage(webpageOneImage)}
                    alt="Weichert webpage - 1"
                  />
                </div>
              </div>
            </div>
            <div className="z-10 grid grid-cols-12 col-start-5 col-end-13 row-start-1 row-end-2 lg:col-start-4 lg:col-end-10">
              <div className="col-span-11 col-start-2">
                <div className="aspect-w-415 aspect-h-170 lg:aspect-w-647 lg:aspect-h-266"></div>
                <div data-aos="fade-up">
                  <GatsbyImage
                    objectFit="contain"
                    className="overflow-hidden rounded-lg shadow"
                    image={getImage(webpageTwoImage)}
                    alt="Weichert webpage - 2"
                  />
                </div>
              </div>
            </div>
            <div className="grid-cols-12 col-start-3 col-end-11 row-start-1 row-end-2 lg:col-start-7 lg:col-end-13 lg:grid">
              <div className="col-span-11 col-start-2">
                <div className="aspect-w-441 aspect-h-707 lg:hidden"></div>
                <div data-aos="fade-up">
                  <GatsbyImage
                    objectFit="contain"
                    className="overflow-hidden rounded-lg shadow"
                    image={getImage(webpageThreeImage)}
                    alt="Weichert webpage - 3"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={`${theme.background} relative pt-clamp-15-42`}>
          <div className="container absolute inset-0 flex">
            <div className="mt-auto aspect-w-326 aspect-h-243 sm:aspect-w-1436 sm:aspect-h-333 bg-white-dark extend before:bg-white-dark after:bg-white-dark"></div>
          </div>
          <MobileDesign>
            <MobileDesign.Phone className="phone-1 sm:pt-clamp-11-19">
              <GatsbyImage
                objectFit="contain"
                className="overflow-hidden shadow rounded-3xl"
                image={getImage(mobileOneImage)}
                alt="Weichert mobile - 1"
              />
            </MobileDesign.Phone>
            <MobileDesign.Phone className="phone-2">
              <GatsbyImage
                objectFit="contain"
                className="overflow-hidden shadow rounded-3xl"
                image={getImage(mobileTwoImage)}
                alt="Weichert mobile - 2"
              />
            </MobileDesign.Phone>
            <MobileDesign.Phone className="phone-3 sm:pt-clamp-11-19">
              <GatsbyImage
                objectFit="contain"
                className="overflow-hidden shadow rounded-3xl"
                image={getImage(mobileThreeImage)}
                alt="Weichert mobile - 3"
              />
            </MobileDesign.Phone>
          </MobileDesign>
        </section>

        <section className="pt-clamp-33-70">
          <Section className="col-span-9 xl:col-span-8">
            <Section.Title>Illustrations that tell a story.</Section.Title>
            <Section.Description>
              <p>
                A clean and ownable illustration style developed for the site allows Weichert to tell visual stories in
                powerful new ways. These custom designs also helped to express abstract concepts like training and
                support in ways that photos simply cannot.
              </p>
            </Section.Description>
          </Section>

          <div className="mt-clamp-18-25">
            <div className="container grid sm:hidden gap-y-11">
              <div data-aos="fade-up">
                <Lottie animation={weichert2007Lotie} />
              </div>
              <div data-aos="fade-up">
                <Lottie animation={weichert2017Lotie} />
              </div>
              <div data-aos="fade-up">
                <Lottie animation={weichert2020Lotie} />
              </div>
            </div>
            <div className="container relative hidden sm:block">
              <Carousel>
                <CursorMarker>
                  <CursorMarker.Gallery />
                </CursorMarker>
                <Carousel.Swiper spaceBetween={16} centeredSlides={false}>
                  <Carousel.Slide style={{ width: "auto" }}>
                    <div
                      data-aos="fade-up"
                      className="overflow-hidden pointer-events-none w-138 sm:h-74 lg:w-268 lg:h-128"
                    >
                      <Lottie animation={weichert2007Lotie} />
                    </div>
                  </Carousel.Slide>
                  <Carousel.Slide style={{ width: "auto" }}>
                    <div
                      data-aos="fade-up"
                      data-aos-delay="200"
                      className="overflow-hidden pointer-events-none w-138 sm:h-74 lg:w-268 lg:h-128"
                    >
                      <Lottie animation={weichert2017Lotie} />
                    </div>
                  </Carousel.Slide>
                  <Carousel.Slide style={{ width: "auto" }}>
                    <div
                      data-aos="fade-up"
                      data-aos-delay="400"
                      className={`w-138 sm:h-74 lg:w-268 lg:h-128 overflow-hidden pointer-events-none ${themeColor}`}
                    >
                      <Lottie animation={weichert2020Lotie} />
                    </div>
                  </Carousel.Slide>
                </Carousel.Swiper>
              </Carousel>
            </div>
          </div>
        </section>

        <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

        <section className="pt-clamp-60-103">
          <div className="container flex flex-col-reverse flex-wrap grid-cols-12 lg:grid">
            <div data-aos="fade-up" className="w-full col-span-6 mt-clamp-16-17 lg:mt-0">
              <div className="hidden sm:block">
                <IconographyDesktop />
              </div>
              <div className="sm:hidden">
                <IconographyMobile />
              </div>
            </div>
            <div className="w-full grid-cols-6 col-span-6 lg:grid">
              <div className="col-span-5 col-start-2">
                <Section.Title>Iconography that adds meaning.</Section.Title>
                <Section.Description>
                  <p>
                    A distinct iconography style was created to connect with our target audiences in a way that only the
                    Weichert brand can. We also developed a custom icon library to add a thoughtful layer of meaning to
                    a diverse variety of subject&mdash;matter and flair to future content.
                  </p>
                </Section.Description>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-clamp-37-74 pb-clamp-43-76">
          <Section className="col-span-10">
            <Section.Title>Faster connections on social media.</Section.Title>
            <Section.Description>
              <p>
                When the site was ready to launch, JK leveraged the new story and visual style across Weichert
                Franchising’s social media footprint, using the yellow highlight bar to create scannable content for
                these short-attention-span channels.
              </p>
            </Section.Description>
          </Section>
          <div className="container flex flex-wrap grid-cols-12 lg:grid mt-clamp-18-25">
            <div className="grid w-full col-span-5 gap-y-14 sm:gap-y-12 lg:gap-y-16">
              <div data-aos="fade-up">
                <GatsbyImage
                  objectFit="contain"
                  className="overflow-hidden rounded-lg shadow"
                  image={getImage(socialMediaOneImage)}
                  alt="Social Media - 1"
                />
              </div>
              <div className="grid grid-cols-6 lg:block">
                <div className="col-span-5 col-start-2 overflow-hidden rounded-lg shadow">
                  <div data-aos="fade-up">
                    <GatsbyImage
                      objectFit="contain"
                      className="overflow-hidden rounded-lg shadow"
                      image={getImage(socialMediaTwoImage)}
                      alt="Social Media - 2"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full col-span-7 lg:grid lg:grid-cols-14 mt-14 sm:mt-12 lg:mt-0">
              <div className="flex items-center col-start-2 col-span-13">
                <div data-aos="fade-up" className="overflow-hidden rounded-lg shadow">
                  <img src={socialMediaThreeGif} alt="Social Media - 3" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <Arrow colorTop="bg-gray-darkest" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

        <section>
          <div className={`${themeColor} pt-clamp-44-55`}>
            <Section className="col-span-10 xl:col-span-9">
              <Section.Title>A new set of selling tools.</Section.Title>
              <Section.Description>
                <p>
                  With the website and social media setting the tone, we then used Weichert’s new story and style to
                  make their flagship print assets more effective and engaging&mdash;an important advantage during this
                  very personal and hands-on portion of the sales cycle.
                </p>
              </Section.Description>
            </Section>

            <div className="container relative mt-clamp-18-25">
              <div className="absolute inset-0 flex items-start ">
                <div className="mt-auto bg-white-dark aspect-w-1436 aspect-h-270 extend before:bg-white-dark after:bg-white-dark"></div>
              </div>
              <Carousel>
                <CursorMarker>
                  <CursorMarker.Print />
                </CursorMarker>
                <Carousel.Swiper
                  breakpoints={{
                    1436: {
                      spaceBetween: 80,
                      slidesPerView: "auto",
                    },
                  }}
                >
                  <Carousel.Slide>
                    <div data-aos="fade-up">
                      <Carousel.Print>
                        <div className="display-mobile-tablet">
                          <GatsbyImage
                            className="w-full h-full"
                            objectFit="contain"
                            image={getImage(printOneTabletImage)}
                            alt="Print 1"
                          />
                        </div>
                        <div className="display-desktop">
                          <GatsbyImage
                            className="w-full h-full"
                            objectFit="contain"
                            image={getImage(printOneDesktopImage)}
                            alt="Print 1"
                          />
                        </div>
                      </Carousel.Print>
                    </div>
                  </Carousel.Slide>
                  <Carousel.Slide>
                    <div data-aos="fade-up" data-aos-delay="200">
                      <Carousel.Print>
                        <div className="display-mobile-tablet">
                          <GatsbyImage
                            className="w-full h-full"
                            objectFit="contain"
                            image={getImage(printTwoTabletImage)}
                            alt="Print 2"
                          />
                        </div>
                        <div className="display-desktop">
                          <GatsbyImage
                            className="w-full h-full"
                            objectFit="contain"
                            image={getImage(printTwoDesktopImage)}
                            alt="Print 2"
                          />
                        </div>
                      </Carousel.Print>
                    </div>
                  </Carousel.Slide>
                  <Carousel.Slide>
                    <div data-aos="fade-up" data-aos-delay="300">
                      <Carousel.Print>
                        <div className="display-mobile-tablet">
                          <GatsbyImage
                            className="w-full h-full"
                            objectFit="contain"
                            image={getImage(printThreeTabletImage)}
                            alt="Print 3"
                          />
                        </div>
                        <div className="display-desktop">
                          <GatsbyImage
                            className="w-full h-full"
                            objectFit="contain"
                            image={getImage(printThreeDesktopImage)}
                            alt="Print 3"
                          />
                        </div>
                      </Carousel.Print>
                    </div>
                  </Carousel.Slide>
                </Carousel.Swiper>
              </Carousel>
            </div>
          </div>
        </section>

        <section className="pt-clamp-24-43 pb-clamp-41-96">
          <div className="container">
            <div className="flex flex-col-reverse grid-cols-2 lg:grid gap-x-4">
              <div className="flex justify-center mt-16">
                <div className="sm:w-4/6 lg:w-full">
                  <Lottie animation={weichert1985Lotie} />
                </div>
              </div>
              <div className="flex justify-center">
                <div className="w-full sm:w-10/12 lg:ml-auto lg:w-5/6">
                  <Section.Title>A story that’s still being written.</Section.Title>
                  <Section.Description>
                    <p>
                      Our relationship with Weichert continues to grow, rooted in a deep understanding of their
                      customers and their brand and a genuine enthusiasm for what sets them apart. It’s a story of
                      collaboration, reimagination, and a focus on driving results that have helped make Weichert one of
                      the fastest growing real estate brands in the nation.
                    </p>
                  </Section.Description>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Arrow colorTop="bg-gray-darkest" colorBottom="bg-white" fillColor="text-white" />
        <Pagination client="weichert" />
      </main>
    </Layout>
  )
}

export default WeichertFranchisingPage

export const weichertQuery = graphql`
  query WeichertPageQuery {
    webpageOneImage: file(relativePath: { eq: "work/weichert/webpage-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    webpageTwoImage: file(relativePath: { eq: "work/weichert/webpage-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    webpageThreeImage: file(relativePath: { eq: "work/weichert/webpage-03.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    mobileOneImage: file(relativePath: { eq: "work/weichert/mobile-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    mobileTwoImage: file(relativePath: { eq: "work/weichert/mobile-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    mobileThreeImage: file(relativePath: { eq: "work/weichert/mobile-03.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printOneDesktopImage: file(relativePath: { eq: "work/weichert/print-01-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printOneTabletImage: file(relativePath: { eq: "work/weichert/print-01-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printTwoDesktopImage: file(relativePath: { eq: "work/weichert/print-02-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printTwoTabletImage: file(relativePath: { eq: "work/weichert/print-02-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printThreeDesktopImage: file(relativePath: { eq: "work/weichert/print-03-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printThreeTabletImage: file(relativePath: { eq: "work/weichert/print-03-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    socialMediaOneImage: file(relativePath: { eq: "work/weichert/social-media-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    socialMediaTwoImage: file(relativePath: { eq: "work/weichert/social-media-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
